$(document).ready(function($) {
    let owl = $('.owl-carousel');
    let homepage_carousel = $('#homepage_carousel');
    let services_carousel = $('#services_carousel');
    let news_carousel = $('#news_carousel');
    let boxes_carousel = $('#boxes_carousel');
    boxes_carousel.owlCarousel({
        loop:true,
        margin:10,
        autoplay:false,
        items: 2
    });
    homepage_carousel.owlCarousel({
        loop:true,
        autoplayTimeout:9000,
        autoplayHoverPause:true,
        autoplay:true,
        dots: false,
        items:2,
        responsive:{
            0:{
                margin:10
            },
            992:{
                margin:20
            }
        }
    });
    services_carousel.owlCarousel({
        loop:true,
        autoplay:true,
        autoplayTimeout:5000,
        dots: false,
        items:2,
        responsive:{
            0:{
                margin:10
            },
            992:{
                margin:20
            }
        }
    });
    news_carousel.owlCarousel({
        loop:true,
        autoplay:true,
        autoplayTimeout:5000,
        dots: false,
        items:2,
        responsive:{
            0:{
                margin:10
            },
            992:{
                margin:20
            }
        }
    });
    owl.owlCarousel({
        loop:true,
        autoplay:false,
        autoplayTimeout:5000,
        dots: false,
        items:2,
        responsive:{
            0:{
                margin:10
            },
            992:{
                margin:20
            }
        }
    });
    $('.ss-control-next').click(function () {
        owl.trigger('next.owl');
    });
    $('.ss-control-prev').click(function () {
        owl.trigger('prev.owl');
    });
});