$(document).ready(function(){
    let theForm = $('.basic_modal');
    let nsForm = $('.newsletter_form');
    let firstForm = $('.first_form');
    let theLoader = $('.form_loader');
    let nsLoader = $('.newsletter_loader');
    let theModal = $(".form_modal");
    let theFirstModal = $(".first_form_modal");

    theForm.submit(function() {
        theLoader.fadeIn();
        $.ajax({
            data: $(this).serialize(),
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            error: function () {
                theLoader.fadeOut();
            }
        });
    });
    theForm.on("successed", function (e) {
        theModal.modal('show');
        theLoader.fadeOut();
    });
    firstForm.submit(function() {
        theLoader.fadeIn();
        $.ajax({
            data: $(this).serialize(),
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            error: function () {
                theLoader.fadeOut();
            }
        });
    });
    firstForm.on("successed", function (e) {
        theFirstModal.modal('show');
        theLoader.fadeOut();
    });

    nsForm.submit(function() {
        nsLoader.fadeIn();
        $.ajax({
            data: $(this).serialize(),
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            error: function () {
                nsLoader.fadeOut();
            }
        });
    });
    nsForm.on("successed", function (e) {
        nsLoader.fadeOut();
    });
});