// noinspection JSJQueryEfficiency

$(document).ready(function($) {
    const nav = $('header');
    let main = $('#main_menu');
    let mmb = $('#mobile_menu_bar');
    let mom = $('#menu_opener_mobile button');
    let myOffcanvas = document.getElementById('main_menu');
    let menu_item = $('.item_mob');

    $(window).ready(function () {
        offcanvas_style();
        mmb.css('top',$(window).height());
    });
    $(window).resize(function () {
        offcanvas_style();
        mmb.css('top',$(window).height());
    });
    $(window).scroll(function () {
        const stickyTop = nav.offset().top;
        if (nav.hasClass('home_header')) {
            if (stickyTop > 50) {
                nav.removeClass('home_header');
                nav.addClass('stuck');
            }
        }
        if (nav.hasClass('stuck')) {
            if (stickyTop < 50) {
                nav.removeClass('stuck');
                nav.addClass('home_header');
            }
        }
    });

    myOffcanvas.addEventListener('hide.bs.offcanvas', function () {
        mom.toggle();
    });
    myOffcanvas.addEventListener('show.bs.offcanvas', function () {
        mom.toggle();
    });

    $('.item_mob').click( function (){
        $('.item_mob').not( $( this ) ).slideToggle();
    });

    function offcanvas_style(){
        if ($(window).width() >= 1200) {
            main.removeClass('offcanvas-bottom menu_mob');
            $('.text_sub_menu').removeClass('item_mob');
        } else {
            main.addClass('offcanvas-bottom menu_mob');
            $('.text_sub_menu').addClass('item_mob');
        }
    }
});


