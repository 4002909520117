// noinspection JSJQueryEfficiency

$(document).ready(function($) {



    // about - resize gallery
    $('#studio').find('.carousel').addClass('col-lg-4');

    // trattamenti - padding testo
    let t_about = $('.trattamenti_about');
    let t_title = $('#span_sub');
    if ( t_about.length && t_title.length ){
        t_about.find('.text').css('padding-top','156px');
    }
    // trattamenti - colore box
    let t_kids = $('.kids');
    if ( t_about.length && t_kids.length ){
        t_about.find('.text').css('background-color','#fbfcff');
    }

    // comparsa laterale pulsante torna indietro
    var backto = $('.back_to');
    if (backto.length){
        $(window).scroll( function (){
            var stickyTop = $(window).scrollTop() + ( $(window).height() ) - 150;
            var backtostop =  backto.offset().top;
            if ( stickyTop >= backtostop ){
                backto.css('transform','translateX(0)');
            }
        });
    }

    // scroll pagina about
    let abc = $('#about_boxes_container');
    if ( abc.length ){
        const ele = document.getElementById('about_boxes_container');
        ele.scrollTop = 0;
        ele.scrollLeft = 0;

        let pos = { top: 0, left: 0, x: 0, y: 0 };

        const mouseDownHandler = function (e) {
            pos = {
                // The current scroll
                left: ele.scrollLeft,
                top: ele.scrollTop,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY,
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };
        const mouseMoveHandler = function (e) {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;
            const dy = e.clientY - pos.y;

            // Scroll the element
            ele.scrollTop = pos.top - dy;
            ele.scrollLeft = pos.left - dx;
        };
        const mouseUpHandler = function () {
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);

            ele.style.cursor = 'grab';
            ele.style.removeProperty('user-select');
        };
        ele.addEventListener('mousedown', mouseDownHandler);
    }
});


